import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import config from "../config";

type UserInfo = {
  accessToken: { accessToken: string };
  userName?: string;
  userFullName?: string;
  email?: string;
  orgId?: number;
  orgName?: string;
  orgShortName?: string;
  userId?: number;
};

type UserContext = {
  userInfo: UserInfo;
  loggedIn: boolean;
  login: (data: UserInfo) => void;
  logout: () => void;
  permissionError: boolean;
  loginFunction: SetStateAction<void>;
  setPermissionError: Dispatch<SetStateAction<boolean>>;
  setLoginFunction: Dispatch<SetStateAction<void>>;
  getUserId(): number;
};

const UserContext = createContext<UserContext>({
  userInfo: null,
  loggedIn: false,
  login: null,
  logout: null,
  permissionError: false,
  loginFunction: null,
  setPermissionError: null,
  setLoginFunction: null,
  getUserId: null,
});

const initialState = {
  userInfo: {
    accessToken: { accessToken: "" },
    userName: "",
    userFullName: "",
    email: "",
    orgId: 0,
    orgName: "",
    orgShortName: "",
    permissions: ["NONE"],
    userId: 0,
  },
  loggedIn: false,
};

const oktaAuth = new OktaAuth(config.oidc);

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [userState, setUserState] = useState({
    userInfo: initialState.userInfo,
  });
  const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [loginFunction, setLoginFunction] = useState(null);
  const { authState } = useOktaAuth();

  const getUserId = (): number => {
    return userState.userInfo.userId;
  };

  const login = async (userData: UserInfo): Promise<void> => {
    setLoggedIn(true);

    // TODO Fill in with application-specific user info call
    // const userInfoData = await getUserInfo();
    // if (userInfoData) {
    //     userData = { ...userData, ...userInfoData };
    //     setUserState({
    //         userInfo: userData
    //     });
    //     setLoggedIn(true);
    // }
  };

  const logout = (): void => {
    localStorage.clear();
    setUserState({ userInfo: initialState.userInfo });
    setLoggedIn(false);
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
      login({ accessToken: authState.accessToken });
    }
  }, [authState]);

  return (
    <UserContext.Provider
      value={{
        userInfo: userState.userInfo,
        loggedIn,
        login,
        logout,
        permissionError,
        setPermissionError,
        loginFunction,
        setLoginFunction,
        getUserId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext<UserContext>(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};

export { UserContext, UserProvider, oktaAuth, useUser, UserInfo };
