import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactDom from "react-dom";
import App from "./App";
import "./index.css";
import WrapperProvider from "./modules/WrapperProvider";

ReactDom.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
  document.getElementById("consumer-products-requests-app"),
);
