import { ThemeType, Traditional, TraditionalColors } from "best-common-react";

export const ConsumerProductsTheme: ThemeType = {
  ...Traditional,
  checkbox: {
    ...Traditional.checkbox,
    borderColor: TraditionalColors["mlb-blue"],
  },
  label: {
    ...Traditional.label,
    "font-weight": "500",
  },
  radio: {
    ...Traditional,
    "font-weight": "500",
  },
};
