import { AlertsFromProvider, BCR, BCRSuspense, LoadingProvider } from "best-common-react";
import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ConsumerProductsTheme } from "./theme/ConsumerProductsTheme";

const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

const App = () => {
  return (
    <Suspense fallback={<BCRSuspense />}>
      <BCR theme={ConsumerProductsTheme}>
        <LoadingProvider>
          <AlertsFromProvider />
          <BrowserRouter>
            <AuthenticatedApp />
          </BrowserRouter>
        </LoadingProvider>
      </BCR>
    </Suspense>
  );
};

export default App;
