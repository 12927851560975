import { Security } from "@okta/okta-react";
import { AlertProvider } from "best-common-react";
import React, { ReactNode } from "react";
import HttpInterceptor from "../api/HttpInterceptor";
import { oktaAuth, UserProvider } from "../contexts/UserContext";

type WrapperProviderProps = {
  children: ReactNode;
};

const WrapperProvider = ({ children }: WrapperProviderProps) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <AlertProvider>
          <HttpInterceptor>{children}</HttpInterceptor>
        </AlertProvider>
      </UserProvider>
    </Security>
  );
};

export default WrapperProvider;
